

  body {
    counter-reset: dot-numbers;
  }
  .projectTit {
    position: absolute;
    border-bottom: 1px solid;
    width: 100%;
    padding: 15px 0;
    z-index: 99;
    padding-left: 15px;
  }
  @media (min-width: 768px) {
  .projectTit {
    position: absolute;
    border-bottom: 1px solid;
    width: 100%;
    padding: 15px 0;
    background: #fff;
    z-index: 99;
    mix-blend-mode: difference;
    color: #fff;
    padding-left: 15px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
  #masterWrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  #panelWrap {
    width: 100%;
  }
  
  section {
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    position: relative;
    margin: 12px;
    font-size: 10px;
    opacity: 1;
  }
  .dot:before {
    position: absolute;
    counter-increment: dot-numbers;
    content: counter(dot-numbers);
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .dot:nth-child(-n+9):before {
    content: '0'counter(dot-numbers);
  }
  .dot:after {
    width: 0;
  border-bottom: 1px solid #FFF;
  content: " ";
  position: absolute;
  left: 0;
  bottom: -1px;
  transition: all ease 0.5s;
  }
  .dot:hover::after {
    width: 100%;
  }
  .dots {
    position: absolute;
    z-index: 100;
    top: 50%;
    right: 58px;
    mix-blend-mode: difference;
    color: #fff;
  }
  
  #downArrow {
    bottom: 15px;
    opacity: 0.1;
    background: #fff;
    mix-blend-mode: difference;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    stroke: #fff;
  }
  
  #upArrow {
    top: 12px;
    display: none !important;
  }
  
  .arrow {
    position: absolute;
    z-index: 100;
    right: 0;
    left: 0;
    margin: auto;
    width: 50px;
    height: 32px;
    stroke-width: 1px;
    stroke: #000;
    fill: transparent;
    stroke-miterlimit:10;
  }
  
  .toolTips {
    position: absolute;
    top: 50%;
    right: 50px;
    z-index: 1000;
  }
  @media (hover:none) {
    .toolTips, p.navDotInfo  {
    visibility: hidden;
  }
  }
  .toolTip {
    opacity: 0;
    text-align: right;
    height: 12px;
    margin: 12px 0;
    line-height: 12px;
    left: 10px;
    top: 10px;
  }
  
  .hideMe {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .creditDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.creditItems {
  flex: 0 0 calc(calc(100% / 3) - 10px);
}
@media (max-width: 767px) {
  .creditItems {
    flex: 0 0 calc(calc(100% / 2) - 7.5px);
  }
}
#Project_Buildyourpage_AddContentBlock_CreditsBlock {
  max-width: 70vw;
    width: calc(100% - 102px);
    overflow: hidden;
}
.Project_Buildyourpage_AddContentBlock_ProjectInfo {
  max-width: 70vw;
    width: calc(100% - 102px);
    overflow: hidden;
}
.Project_Buildyourpage_AddContentBlock_TextBlock {
  max-width: 70vw;
    width: calc(100% - 102px);
    overflow: hidden;
}
.Project_Buildyourpage_AddContentBlock_ProjectInfo h3 {
margin-top: 30px;
}
.Project_Buildyourpage_AddContentBlock_ProjectInfo h3:first-of-type {
  margin-top: 0;
  }
.Project_Buildyourpage_AddContentBlock_DoubleProjectImage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
    width: calc(100% - 30px);
    max-width: 70vw;
}
.Project_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer {
  max-width: calc(50% - 7.5px);
}
.Project_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer img {
  width: 100%;
  height: auto;
}
video {
  width: 100%;
    height: auto;
}
.Project_Buildyourpage_AddContentBlock_SingleProjectVideo .videoContainer {
  max-width: 70vw;
    width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_SmallProjectVideo .videoContainer {
  max-width: 600px;
    width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_FullScreenVideo .videoContainer {
  max-width: 100%;
  height: 100%;
}
.Project_Buildyourpage_AddContentBlock_FullScreenVideo .videoContainer video {
width: 100%;
height: auto;
object-fit: cover;
position: absolute;
top: 0;
left: 0;
height: 100%;
}
.Project_Buildyourpage_AddContentBlock_DoubleProjectVideo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(100% - 132px);
}
.Project_Buildyourpage_AddContentBlock_DoubleProjectVideo .videoContainer {
  max-width: calc(50% - 7.5px);
}
.Project_Buildyourpage_AddContentBlock_DoubleProjectVideo .videoContainer video {
aspect-ratio: 16 / 9;
    height: 100%;
    object-fit: cover;
}
.Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 70vw;
}
.Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
      width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .videoContainer {
  max-width: calc(50% - 7.5px);
}
.Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .videoContainer {
  max-width: calc(50% - 7.5px);
}
.Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .videoContainer video {
  width: 100%;
    height: 100%;
    object-fit: cover;
}
.Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .videoContainer video {
  width: 100%;
    height: 100%;
    object-fit: cover;
}
.Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .imageContainer {
  max-width: calc(50% - 7.5px);
}
.Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .imageContainer {
  max-width: calc(50% - 7.5px);
}
.textOverImage {
  opacity: 0;
}
@media (max-width: 767px) {
  #panelWrap {
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
  -ms-overflow-style: none;
  }
  #panelWrap::-webkit-scrollbar {
    width: 0;
  height: 0;
  }
#panelWrapTwo section {
  height: auto;
  margin-bottom: 60px;
}
#Project_Buildyourpage_AddContentBlock_CreditsBlock {
  max-width: 100%;
    width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_ProjectInfo {
  max-width: 100%;
    width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_TextBlock {
  max-width: 100%;
  width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_FullScreenImage {
  height: calc(100vh - 30px);
  width: calc(100vw - 30px);
}
#Page_Buildyourpage_AddContentBlock_CreditsBlock {
  max-width: 100%;
    width: calc(100% - 30px);
}
.Page_Buildyourpage_AddContentBlock_ProjectInfo {
  max-width: 100%;
    width: calc(100% - 30px);
}
.Page_Buildyourpage_AddContentBlock_TextBlock {
  max-width: 100%;
  width: calc(100% - 30px);
}
.Page_Buildyourpage_AddContentBlock_FullScreenImage {
  height: calc(100vh - 30px);
  width: calc(100vw - 30px);
}
.FullScreenVideo {
  height: calc(100vh - 30px);
  width: calc(100vw - 30px);
}
.DoubleImageRightAndVideoBlock .videoContainer, .DoubleImageLeftAndVideoBlock .videoContainer, .DoubleProjectVideo .videoContainer, .DoubleProjectImage .imageContainer, .DoubleImageRightAndVideoBlock .imageContainer, .DoubleImageLeftAndVideoBlock .imageContainer {
  margin-top: 60px;
}
#panelWrapTwo .DoubleImageRightAndVideoBlock, #panelWrapTwo .DoubleImageLeftAndVideoBlock, #panelWrapTwo .DoubleProjectVideo, #panelWrapTwo .DoubleProjectImage {
  margin-bottom: 120px;
}
}
.hideMeTwo {
  overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
}
.hideMeTwo::-webkit-scrollbar {
  width: 0;
height: 0;
}
.hideMeTwo .projectTit {
  position: relative;
}
.hideMeTwo .Project_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer {
  max-width: 100%;
}
.hideMeTwo .Project_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer:last-of-type {
margin-top: 60px;
}
.hideMeTwo .Page_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer {
  max-width: 100%;
}
.hideMeTwo .Page_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer:last-of-type {
margin-top: 60px;
}
#projectPage  .hideMe .Project_Buildyourpage_AddContentBlock_DoubleProjectImage .imageContainer img {
  max-height: calc(var(--app-height) - 98.1px);
  object-fit: cover;
}
#projectPage .hideMe .Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .imageContainer img {
  max-height: calc(var(--app-height) - 98.1px);
  object-fit: cover;
}
#projectPage  .hideMe .Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .imageContainer img {
  max-height: calc(var(--app-height) - 98.1px);
  object-fit: cover;
}
.imageContainer {
  overflow: hidden;
  padding: 1px 0;
}
.videoContainer {
  overflow: hidden;
  padding: 1px 0;
}
.imgreveal {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.hideMe .imageContainer img {
  max-height: calc(var(--app-height) - 98.1px);
}
#projectPage .hideMe .Project_Buildyourpage_AddContentBlock_FullScreenImage .imageContainer img {
  max-width: 100%;
  max-height: 100%;
}
#projectPage .hideMe .Project_Buildyourpage_AddContentBlock_FullScreenVideo .imageContainer img {
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .Project_Buildyourpage_AddContentBlock_FullScreenVideo .videoContainer {
    min-height: calc(var(--app-height) - 68.1px);
}

}
.hideMe3 {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 100%;
}
.hideMe3::-webkit-scrollbar {
  display: none;
}
.Project_Buildyourpage_AddContentBlock_SingleProjectVideo .videoContainer {
  max-width: 70vw;
    width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_SmallProjectVideo .videoContainer {
  max-width: 600px;
    width: calc(100% - 30px);
}
.Project_Buildyourpage_AddContentBlock_FullScreenVideo .videoContainer {
  max-width: 100%;
  height: 100%;
}
.Project_Buildyourpage_AddContentBlock_FullScreenVideo .videoContainer video {
width: 100%;
height: auto;
object-fit: cover;
position: absolute;
top: 0;
left: 0;
height: 100%;
}
.Page_Buildyourpage_AddContentBlock_DoubleProjectVideo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(100% - 132px);
}
.Page_Buildyourpage_AddContentBlock_DoubleProjectVideo .videoContainer {
  max-width: calc(50% - 7.5px);
}
.Page_Buildyourpage_AddContentBlock_DoubleProjectVideo .videoContainer video {
aspect-ratio: 16 / 9;
    height: 100%;
    object-fit: cover;
}
.Page_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 70vw;
}
.Page_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
      width: calc(100% - 30px);
}
.Page_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .videoContainer {
  max-width: calc(50% - 7.5px);
}
.Page_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .videoContainer {
  max-width: calc(50% - 7.5px);
}
.Page_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .videoContainer video {
  width: 100%;
    height: 100%;
    object-fit: cover;
}
.Page_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .videoContainer video {
  width: 100%;
    height: 100%;
    object-fit: cover;
}
.Page_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock .imageContainer {
  max-width: calc(50% - 7.5px);
}
.Page_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock .imageContainer {
  max-width: calc(50% - 7.5px);
}