@mixin text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  // Configured in Step 1
  $top-crop: 8;
  $bottom-crop: 9;
  $crop-font-size: 100;
  $crop-line-height: 0.9;
  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
  $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
  // Mixin output
  line-height: $line-height;
  &::before,
  &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
  }
  &::before {
      margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
  }
  &::after {
      margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}


 :root {
  --app-height: 100%;
 }
 
 html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
#loader #root {
  border: 1px solid transparent !important; 
 }
/* Sections
   ========================================================================== */

/**
 * Remove the margin in all browsers.
 */

body {
  margin: 0;
  color: #000;
}

/**
 * Render the `main` element consistently in IE.
 */

main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
  border-top: 1px solid #000;
  margin-block-end: 13px;
    margin-block-start: 15px;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

* {
  cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26' height='15' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 14.43'%3E%3Cpolygon points='25 6.22 13.5 6.22 13.5 0 11.5 0 11.5 6.22 0 6.22 0 8.22 11.5 8.22 11.5 14.43 13.5 14.43 13.5 8.22 25 8.22 25 6.22' style='fill:%23fff;'/%3E%3Cpolygon points='25 6.72 13 6.72 13 0 12 0 12 6.72 0 6.72 0 7.72 12 7.72 12 14.43 13 14.43 13 7.72 25 7.72 25 6.72'/%3E%3C/svg%3E") 11 8, pointer;
}
/* cursors*/
a, button, #menuButton path, #menuButton svg, #invert span, .slide img, .clListTitleP, .zoomed, .dots, #refThree canvas, #downArrow {
  cursor: url("data:image/svg+xml,%3Csvg width='26' height='15' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29 16.74'%3E%3Cg id='active'%3E%3Cpath id='active_boarder' d='m27,8.37L14.5,1.15,2,8.37l12.5,7.22,12.5-7.22Z' style='fill:none; stroke:%23fff; stroke-miterlimit:10; stroke-width:2px;'/%3E%3Cpath id='active-2' d='m27,8.37L14.5,1.15,2,8.37l12.5,7.22,12.5-7.22Z' style='fill:none; stroke:%23000; stroke-miterlimit:10;'/%3E%3C/g%3E%3C/svg%3E") 11 8, auto;
}



/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=812bf288-77a8-4b7b-86e9-52ed337d8d46");
@font-face{
    font-family:"NeueHaasGroteskText W01";
    src:url("a14594bf-73de-4b5f-9792-9566994a021d.woff2") format("woff2"),url("bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff") format("woff");
}
@font-face{
    font-family:"NHaasGroteskTXW01-56It";
    src:url("dc9df9ed-36b9-4522-8e57-1a899ed2c224.woff2") format("woff2"),url("ff571a3a-fb16-42b1-a691-23d8955aa35e.woff") format("woff");
}
@font-face{
    font-family:"NHaasGroteskTXW01-75Bd";
    src:url("d13fb250-6b64-4d97-85df-51fc6625a891.woff2") format("woff2"),url("60fa2ce6-c35e-4203-9bbf-25dd128daec5.woff") format("woff");
}
@font-face{
    font-family:"NHaasGroteskTXW01-76BdI";
    src:url("d1fbf511-d681-4002-b57e-cabb331b3b2e.woff2") format("woff2"),url("135bdd95-f711-4095-8be6-fce6d3f9ef54.woff") format("woff");
}


.App {
  text-align: center;
  height: 100%;
  position: relative;
  z-index: 1;
}
.container {
  height: 100%;
  /* overflow: scroll; */
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.standardScroll {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  max-width: calc(100%);
  margin: 0 auto;
  overflow-x: hidden;
}
.standardScroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
#standardContainer {
  height: 100%;
  /* overflow: scroll; */
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: block;
  position: relative;
  border-radius: 0;
  width: 100%;
  max-width: calc(100% - 30px);
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 1px;
}
#standardContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
#root {
  height: calc(var(--app-height) - 32px);
  position: fixed;
  border: 1px solid #000;
  margin: 15px;
  width: calc(100vw - 32px);
  overflow: hidden;
  display: flex;
  background: #fff;
}
html {
  background: #fff;
}

 
body {
  background: #fff;
  font-family:"NeueHaasGroteskText W01";
  text-transform: uppercase;
  margin: 0;
}
main {
  height: calc(100% + 1px);
  top: -1px;
  flex: 1 1 auto;
  margin: 0 auto;
  width: calc(100% + 1px);
    max-width: calc(100% + 1px);
    margin-left: -1px;
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid;
}
.custom-cursor {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  background-blend-mode: difference;
  width: 25px;
  height: 15px;
  background: #fff;
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 9999;
}
.custom-cursor:after {
  position: absolute;
  content:"";
  width: 25px;
  height: 15px;
  background-size: 25px 15px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' width='26' height='15' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 14.43'%3E%3Cpath d='m0,7.22h25' style='fill:none; stroke:%23000; stroke-miterlimit:10;'/%3E%3Cpath d='m12.5,14.43V0' style='fill:none; stroke:%23000; stroke-miterlimit:10;'/%3E%3C/svg%3E");
  z-index: 1000;
  background-position: center center;
  top: -50%;
  left: -50%;
  margin: auto;
}
.custom-cursor--link:after {
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' width='26' height='15' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 15.59'%3E%3Cpath d='m26,7.79L13.5.58,1,7.79l12.5,7.22,12.5-7.22Z' style='fill:none; stroke:%23000; stroke-miterlimit:10;'/%3E%3C/svg%3E");
}
.flexFields {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.flexContainer {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.flexContainer::-webkit-scrollbar {
  display: none;
}
.flexFields .flexContainer section {
}
.Project_Buildyourpage_AddContentBlock_FullScreenImage {
  height: 100%;
  width: 100%;
}
.Page_Buildyourpage_AddContentBlock_FullScreenImage {
  height: 100%;
  width: 100%;
}
.imageContainer {
  height: 100%;
  width: 100%;
}
.flexFields::-webkit-scrollbar {
  display: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.textArea {
  position: absolute;
  color: #000;
  mix-blend-mode: difference;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
h1, h2, h3, h4, h5 ,h6 {
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: #000;
}
h1 {
  
}
h2 {
  color: #000;
}
img {
  width: 100%;
  height: auto;
}
p {
font-size: 11px;
@include text-crop(1.63);
color: #000;
margin: 0;
max-width: 451px;
text-transform: none;
}
p span {
  margin-right: 10px;
}
p span:last-of-type {
  margin-right: 0;
}
footer {
  position: absolute;
  bottom: 50px;
  left: 50px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  z-index: 9999;
}
#openMenu {
  margin: 0;
  padding: 0;
  line-height: 0;
}
#invert span {
}
.light {
color: #000;
}
.dark {
color: #707070;
}
.App, canvas {
  mix-blend-mode: difference;
}
@media (max-width: 469px) {
  p span {
    display: block;
    margin-bottom: 5px;
  }
}
a {
  color: #000;
  text-decoration: none;
  position: relative;
}

a:hover::after {
  width: 100%;
}
.light {
  position: relative;
}
.dark {
  position: relative;
}

#refThree canvas {
  transition: ease all 4s;
    transform: scale3d(0, 0, 0);
}
#refThree.updated canvas {
    transform: scale3d(1, 1, 1);
}

#showMe.updated {
  opacity: 1;
}
#vfBorder {
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  margin: auto;
}
.point {
  stroke-width:0.5;fill: transparent;
  width: 100%;
  height: 100%;
}
svg#vision_factory {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
    height: 500px;
    bottom: 0;
    right: 0;
    margin: auto;
}
svg#vision_factory_sq {
  position: absolute;
  top: 0;
  left: 0;
}
rect {
  stroke-width: 1px;
}
#vf-outline-sq {
  stroke: rgb(0, 0, 0);
  visibility: hidden;
}
#smooth-wrapper {
  transition: ease all 4s;
}

#smooth-wrapper.remove {
  transform: scale(15);
}
#smooth-wrapper.remove #vf-outline {
}
.page-container {
  opacity: 1;
  height: 100%;
  width: 100%;
}
.page-container.added {
  opacity: 1;
}
.fullHeight {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#projectTitle {
  border-bottom: 1px solid #000;
}
#projectTitle h1 {
  font-size: 2rem;
  letter-spacing: 0;
}
#counter {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: red;
  width: 10px;
  height: 40px;
  margin: auto;
  z-index: 9999;
}
.Project_Buildyourpage_AddContentBlock_TextBlock {
  max-width: 1000px;
    width: calc(100% - 30px);
}
.Page_Buildyourpage_AddContentBlock_TextBlock {
  max-width: 1000px;
    width: calc(100% - 30px);
}
.TextBlock h3 {
  font-size: 10px;
  margin-bottom: 0;
  margin-top: 0;
}
.Project_Buildyourpage_AddContentBlock_SmallSingleProjectImage img {
  max-width: 1000px;
    width: auto;
}
.Project_Buildyourpage_AddContentBlock_SingleProjectImage img {
  max-width: 1000px;
    width: auto;
}
.Page_Buildyourpage_AddContentBlock_SmallSingleProjectImage img {
  max-width: 1000px;
    width: auto;
}
.Page_Buildyourpage_AddContentBlock_SingleProjectImage img {
  max-width: 1000px;
    width: auto;
}
#projectPage .hideMe .imageContainer img {
  max-width: 70vw;
}
#projectPage .Project_Buildyourpage_AddContentBlock_FullScreenImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}
#standardPage .hideMe .imageContainer img {
  max-width: 70vw;
}
#standardPage .Page_Buildyourpage_AddContentBlock_FullScreenImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}


.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textOverImage {
  position: absolute;
}
#featuredProjects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background: #fff;
  margin: 0 auto;
}
#featuredProjects #container {
  max-width: 50vw;
  width: calc(100% - 30px);
}
@media (hover: none) and (pointer: coarse) and (orientation: landscape) {
  #featuredProjects #container {
    max-width: 70vw;
  }
}
#container {
  display: block;
  position: relative;
  border-radius: 0;
  width: 100%;
  max-width: calc(100%);
  margin: 0 auto;
  box-sizing: border-box;
}

.slide {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #000;
  border-radius: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 40px;
  color: white;
  position: absolute;
  border: none;
  opacity: 0.7;
  box-sizing: border-box;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  aspect-ratio: 16 / 9;
}

.controls {
  flex: 0 0 100%;
  width: 100%;
  position: absolute;
  width: 0;
  visibility: hidden;
}
.slideImage {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  background: #000;
}
.absoluteTitle {
  position: absolute;
    top: 0;
    z-index: 99;
    transform: translate(calc(0px), calc(-50%));
    left: auto;
    color: #000;
    height: fit-content;
    width: 100%;
    max-width: 600px;
    margin: auto;
}
.absoluteTitle h2 {
  max-width: 800px;
}
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
#loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  transition: ease all 3s;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg id='Group_2' data-name='Group 2' xmlns='http://www.w3.org/2000/svg' width='50' height='57.738' viewBox='0 0 50 57.738'%3E%3Cg id='Group_1' data-name='Group 1' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M45.666,21.554,44.611,5.593a1.643,1.643,0,0,0-.864-1.336,4.192,4.192,0,0,0-4.165,0,1.646,1.646,0,0,0-.866,1.336l-.507,7.669L33.333,4.811,29.165,7.216,25,0,0,14.433V43.3L25,57.738,50,43.3V24.057l-4.332-2.5Z' fill='%23fff' stroke='%23000' style='fill: rgb(0, 0, 0); stroke: rgb(0, 0, 0); stroke-width: 1px;'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
   animation: flickerAnimation 1.5s infinite;
}
.slideLink {
  display: block;
  text-decoration: none;
}
.slideLink:hover {
  text-decoration: none;
}
.slideLink:after {
  display: none;
}
html.dark img {
  filter: invert(1) !important;
}
html.dark video {
  filter: invert(1) !important;
}
section#worksProjects {
  padding: 0;
  height: calc(100% - 15px);
  width: 100%;
}
section#worksProjects {
  #standardContainer {
    padding-top: 15px;
  }
}
.clList {
  margin-bottom: 15px;
  position: relative;
}
#worksProjects p {
  transition: ease all 1s;
  position: relative;
}
.clListTitle p:after {
  width: 0;
  border-bottom: 1px solid #000;
  content: " ";
  position: absolute;
  left: 0;
  bottom: -2px;
  transition: all ease 0.5s;
}
.clListTitle p:hover::after {
  width: 100%;
}
.clListImage {
  display: flex;
  position: relative;
  top: -3.75px;
}
.straightLine {
  height: 1px;
  width: 0;
  background: #000;
}
.square {
  width: calc(100% / 3);
  aspect-ratio: 16/9;
  position: absolute;
  right: calc(100% / 3);
}
.square .top-side {
  height: 1px;
  width: 0;
  background: #000;
  display: block;
  position: absolute;
  top: 0;
}
.square .bottom-side {
  height: 1px;
  width: 0;
  background: #000;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}
.square .left-side {
  height: 0;
  width: 1px;
  background: #000;
  display: block;
  left: 0;
  bottom: 0;
  position: absolute;
}
.square .right-side {
  height: 0;
  width: 1px;
  background: #000;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.squareImage {
  width: calc(100% - 30px);
    height: calc(100% - 30px);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    visibility: hidden;
    opacity: 0;
    background: #fff !important;
    padding: 14px;
    z-index: 99;
}
.dark .squareImage {
    background: #000 !important;
}
.clListImage {
  transition: ease all 1s;
}
.clListTitle {
  width: fit-content;
  height: 13px;
}
.clListTitle:hover ~ .clListImage {
  display: flex;
}
#worksProjects .pageTitle {
  font-size: 10px;
  position: absolute;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  border: none;
}
#worksProjects .pageTitle.active {
  opacity: 1;
  visibility: visible;
  }
#worksProjects .pageTitle p {
  font-size: 10px;
  z-index: 999;
}
#worksProjects .pageTitle p a {
z-index: 999;
}
#worksProjects .pageTitle p:hover {
  font-size: 10px;
}
.jumbo {
  margin: 0;
  font-size: clamp(4.7rem, 16vw, 41.75rem);
  line-height: 0.9333333333333333;
  font-weight: 300;
  letter-spacing: -1vw;
  transition: all 1.2s cubic-bezier(.44,.15,.2,1)!important;
  mix-blend-mode: difference;
  color: #000;
  @include text-crop(0.9333333333333333);
}
.large {
  font-size: clamp(35px, 10vw, 100px);
  line-height: 0.9;
  letter-spacing: -0.075em;
  text-transform: uppercase;
  @include text-crop(0.9);
  margin-left: clamp(-9px, -6px, 3px);
}
.medium {
font-size: 35px;
line-height: 1;
letter-spacing: -0.06em;
text-transform: uppercase;
@include text-crop(1);
}
.small {
font-size: 10px;
line-height: 1;
text-transform: uppercase;
@include text-crop(1);
}
.ourCredits p {
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  @include text-crop(1);
}
.featCont {
  position: absolute;
  display: flex;
  z-index: 11;
  transition: ease all 1s;
}
#feattitle {
  padding: 15px 0 0 15px;
}
div#allWorksLink {
  position: absolute;
  bottom: 13px;
  left: 0;
  right: 0;
  z-index: 99;
  margin: 0 auto;
  width: fit-content;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
}
div#allWorksLink a {
  bottom: -25px;
  display: block;
}
.oneone  {
  aspect-ratio: 1 / 1;
  }
  .sixnine {
    aspect-ratio: 16 / 9;
  
  }
  .twothree  {
    aspect-ratio: 2 / 3;
  
  }
  .threetwo {
    aspect-ratio: 3 / 2;
  
  }
  .hideShow {
    overflow: hidden;
    padding: 2px;
    position: relative;
    background: white;
    margin-bottom: 30px;
}
.hideShow hr {
  position: relative;
  left: -101%;
}
.hideShow h3 {
  position: relative;
  top: -25px;
}
.hideShow p {
  position: relative;
  bottom: -200px;
}
.hideShowCredits {
  overflow: hidden;
  padding: 2px;
  position: relative;
  background: white;
}
.hideShowCredits hr {
position: relative;
left: -101%;
}
.hideShowCredits h2 {
  position: relative;
  top: -25px;
  }
  .hideShowCredits .creditDiv {
    overflow: hidden;
    background: #fff;
  }
  .hideShowCredits .creditDiv .creditItems {
    position: relative;
    background: white;
    overflow: hidden;
    padding: 2px 0;
    top: -400px;
    }
    .clListTitle {
      overflow: hidden;
      background: #fff;
      padding: 2px 0 0 0;
    }
    .clListTitle p {
      top: -25px;
    }
    #works div#allWorksLink {
      overflow: hidden;
      background: #fff;
      padding: 2px 0 0 0;
    }
    #works div#allWorksLink a {
      bottom: -25px;
      display: block;
    }
    .innerHtml p {
      position: relative;
      padding: 2px 0;
      font-size: clamp(35px, 10vw, 100px);
      line-height: 0.9;
      letter-spacing: -0.075em;
      text-transform: uppercase;
      @include text-crop(0.9);
      margin-left: clamp(-9px, -6px, 3px);
      max-width: 100%;
    }
    .Project_Buildyourpage_AddContentBlock_RelatedWorks {
      max-width: 70vw;
      width: calc(100% - 30px);
  }
  .Project_Buildyourpage_AddContentBlock_RelatedWorks .masonry {
    margin-top: 15px;
  }
  .Project_Buildyourpage_AddContentBlock_RelatedWorks .masonry .gridTitle h3 {
    color: #000;
    top: 0;
    position: relative;
}
.Project_Buildyourpage_AddContentBlock_RelatedWorks .masonry .gridArtist h4 {
  color: #000;
  bottom: 0;
}
.Page_Buildyourpage_AddContentBlock_RelatedWorks {
  max-width: 70vw;
  width: calc(100% - 30px);
}
.Page_Buildyourpage_AddContentBlock_RelatedWorks .masonry {
margin-top: 15px;
}
.Page_Buildyourpage_AddContentBlock_RelatedWorks .masonry .gridTitle h3 {
color: #000;
top: 0;
position: relative;
}
.Page_Buildyourpage_AddContentBlock_RelatedWorks .masonry .gridArtist h4 {
color: #000;
bottom: 0;
}
@media (max-width: 767px) {
  section#worksProjects.mobile #standardContainer {
  padding-bottom: 120px;
}
}
.Sitemapcontainer {
  padding-left: 15px;
  padding-bottom: 60px;
  padding-top: 15px;
}
.Sitemapcontainer p {
margin-bottom: 15px;
}
.Sitemapcontainer h3 {
  margin-bottom: 15px;
  }
#sitemap #page-container {
overflow-y: scroll;
scrollbar-width: none;
  -ms-overflow-style: none;
}
#sitemap #page-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}