#smooth-wrapper {
  display: block !important;
  width: 100% !important;
}
div#slideUp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  margin: auto;
  z-index: 9999;
  width: 100px;
  height: 10px;
  overflow: hidden;
  mix-blend-mode: difference;
}
#showMe {
  color: #fff;
    position: absolute;
    bottom: -100px;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 300;
    opacity: 1;
    height: fit-content;
    margin: 0 auto;
    left: 0;
    right: 0;
}
#root {
  position: relative;
}
#root.updated {
  border-color: transparent;
  border: none;
}
#top {
position: absolute;
top: 1px;
left: 0;
display: block;
height: 1px;
background: #000;
width: 0;
}
#right {
  height: 0;
  width: 1px;
  background: #000;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
#bottom {
  height: 1px;
  width: 0;
  background: #000;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}
#left {
  height: 0;
  width: 1px;
  background: #000;
  display: block;
  left: 0;
  bottom: 0;
  position: absolute;
}
#refThree {
left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed !important;
}
#loader .copyCopy {
  border: 0;
}