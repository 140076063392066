#aboutPage .whatwedoContent {
  max-width: 100%;
  width: 100%;
  display: block;
  margin-bottom: 60px;
  mix-blend-mode: difference;
}
#aboutPage #smooth-wrapper {
  position: relative;
  display: flex;
    justify-content: center;
    align-items: center;
    transition: none;
}
#aboutPage #refThree canvas {
  filter: invert(0);
}
#aboutPage #smooth-wrapper {
  padding: 15px;
  max-width: calc(100% - 30px);
  height: 100%;
  mix-blend-mode: difference;
  color: #000;
  -webkit-text-fill-color: #fff;
}
#aboutPage .innerHtml {
  position: relative;
  max-width: 70vw;
}

#aboutPage .split-parent {
  overflow: hidden;
}
#aboutPage .split-child {
  display: inline-block;
}
#aboutPage #smooth-wrapper #smooth-content {
  position: fixed;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
#aboutPage .innerHtml p {
 font-size: clamp(32px, 10vw, 100px);
 margin-left: clamp(-3px, 100px, -0.7%);
 margin-bottom: 60px;
}
#aboutPage .pageTitle {
  display: none;
}

#aboutPage #allWorksLink {
  mix-blend-mode: difference;
}